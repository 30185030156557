import {useState} from 'react'
import groupBy from 'lodash/groupBy'
import sample from 'lodash/sample'
import {useEffectOnce} from 'react-use'

import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import {HeaderXl, Header3, ExtraSmallBody} from 'src/components/text'
import {Grid, Flex, Box} from 'src/components/Box'
import ButtonLink from 'src/components/ButtonLink'
import {localPaths} from 'src/urls'

interface GifSourceSet {
  name: string
  mp4?: string
  webm?: string
  gif?: string
}

interface DataType {
  allFile: {
    nodes: Array<{
      id: string
      extension: string
      name: string
      publicURL: string
    }>
  }
}

interface Props {
  allData: DataType
}

const useFailGifs = (data: DataType) => {
  return Object.entries(groupBy(data.allFile.nodes, 'name')).map(
    ([name, items]) =>
      items.reduce<GifSourceSet>(
        (acc, {publicURL, extension}) => {
          if (
            publicURL &&
            (extension === 'mp4' || extension === 'webm' || extension === 'gif')
          ) {
            acc[extension] = publicURL
          }

          return acc
        },
        {name}
      )
  )
}

const NotFoundImage = ({allData}: Props) => {
  const data = useFailGifs(allData)
  const [src, setSrc] = useState<GifSourceSet | undefined>()

  useEffectOnce(() => {
    const imageSrc = sample(data)
    if (imageSrc) {
      setSrc(imageSrc)
    }
  })

  if (!src) {
    return <Box height="200px" width="200px" />
  }

  return (
    <Grid gridGap="1" justifyContent="center">
      <video
        {...({
          disableremoteplayback: 'true',
        } as any)}
        disablePictureInPicture
        height="200px"
        css={{
          maxHeight: '200px',
          maxWidth: '100%',
        }}
        autoPlay
        loop
        muted
        playsInline
      >
        {src.webm && <source src={src.webm} type="video/webm" />}
        {src.mp4 && <source src={src.mp4} type="video/mp4" />}
        {src.gif && <img src={src.gif} alt="awkward" />}
        Well this is is awkward
      </video>
      <ExtraSmallBody textAlign="right">
        from{' '}
        <a css={{fontSize: '1em'}} href="https://giphy.com">
          giphy.com
        </a>
      </ExtraSmallBody>
    </Grid>
  )
}

const NotFoundPage = ({allData}: Props) => (
  <Layout footerMargin="7">
    <SEO title="404: Not found" />
    <Grid mt="4" gridGap="4">
      <HeaderXl textAlign="center">Awkward.</HeaderXl>
      <Header3 textAlign="center">
        We can’t find the page you’re looking for.
      </Header3>

      <NotFoundImage allData={allData} />

      <Flex justifyContent="center">
        <ButtonLink to={localPaths.home}>Go Home</ButtonLink>
      </Flex>
    </Grid>
  </Layout>
)

export default NotFoundPage
