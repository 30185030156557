import {RouteComponentProps} from '@reach/router'
import {graphql} from 'gatsby'

import NotFoundPage from 'src/components/NotFoundPage'

interface Props extends RouteComponentProps {
  data: {
    allFile: {
      nodes: Array<{
        id: string
        extension: string
        name: string
        publicURL: string
      }>
    }
  }
}

const Four04 = ({data}: Props) => <NotFoundPage allData={data} />

export const query = graphql`
  query FailGifs {
    allFile(
      filter: {
        extension: {regex: "/(gif)|(mp4)|(webm)/"}
        relativeDirectory: {eq: "404"}
      }
    ) {
      nodes {
        id
        extension
        name
        publicURL
      }
    }
  }
`

export default Four04
